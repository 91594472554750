<!--
 * @Author: sunfei
 * @Date: 2022-03-15 10:50:55
 * @LastEditTime: 2022-12-08 09:13:24
 * @FilePath: \cloud-platform\src\components\MentTree.vue
 * @Description: 
-->
<template>
  <div>
    <template v-for="(menu, index) in menuData">
      <el-submenu
        :key="menu.path"
        :index="index + ''"
        v-if="menu.sunList.length && menu.path !== 'Outerchain'"
        @click="saveNavState(menu.path)"
      >
        <template slot="title">
          <i :class="menu.icon"></i>
          <span slot="title">{{ menu.name }}</span>
        </template>
        <menu-tree :menuData="menu.sunList"></menu-tree>
      </el-submenu>
      <el-menu-item
        :key="menu.path + ''"
        :index="menu.path + ''"
        v-else-if="menu.path !== 'Outerchain'"
        @click="saveNavState(menu.path)"
      >
        <i :class="menu.icon"></i>
        <span slot="title">{{ menu.name }}</span>
      </el-menu-item>
      <!--  -->
      <router-link
        class="screen actives"
        v-else-if="menu.path == 'Outerchain'"
        target="_blank"
        :to="{ path: '/Outerchain' }"
        >{{ menu.name }}</router-link
      >
    </template>
  </div>
</template>
 
<script>
export default {
  name: 'MenuTree',
  props: ['menuData'],
  methods: {
    saveNavState(activePathypt) {
      this.$cookies.set('activePathypt', activePathypt)
      var items = activePathypt.split('/')
      var activepath = items.join('')
      this.$store.commit('setactivetagTitle', activepath)
      // if (activePathypt == 'Outerchain') {
      //   this.$router.push('Welcome')
      //   window.open('http://110.52.215.192:9000/system/park/parkData')
      // }
    },
  },
}
</script>
<style lang="scss" scoped>
.screen {
  padding-left: 20px;
  color: rgb(255, 255, 255);
  background-color: rgb(51, 55, 65);
  text-decoration-line: none;
  font-size: 14px;
  width: 200px;
  display: inline-block;
  height: 56px;
  line-height: 56px;
}
.screen:hover {
  background-color: rgb(41, 44, 54);
}
</style>