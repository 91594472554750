
<template>
  <div class="container">
    <el-container>
      <el-aside
        :width="togglecollapse ? '64px' : '200px'"
        style="transition: all 0.3s ease"
      >
        <!-- 实现菜单多级分类 -->
        <el-scrollbar style="height: 100%">
          <el-menu
            class="el-menu-vertical-demo"
            unique-opened
            router
            :collapse="togglecollapse"
            :collapse-transition="false"
            :default-active="currentactivePathypt"
            :default-openeds="openeds"
            text-color="#fff"
            background-color="#333741"
            active-text-color="#409eff"
          >
            <div class="logo">
              <h4>平治停车管理云平台</h4>
            </div>
            <!-- 引入组件               @activePathypt="activePaths"-->
            <menu-tree :menuData="menuList"></menu-tree>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-container>
        <el-header>
          <div class="left">
            <div class="togglebtn" @click="togglebtn">
              <i v-if="!togglecollapse" class="el-icon-s-fold"></i>
              <i v-else class="el-icon-s-unfold"></i>
            </div>
            <div>欢迎你，{{ Admin }}</div>
          </div>
          <div class="right">
            <div class="btn-rank" @click="handleFullScreen">
              <el-tooltip
                effect="dark"
                :content="fullscreen ? `取消全屏` : `全屏`"
                placement="bottom"
              >
                <i class="el-icon-rank"></i>
              </el-tooltip>
            </div>
            <div class="btnout">
              <i class="el-icon-user-solid user"></i>
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  用户<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item divided @click.native="isShowXGMM = true"
                    >修改密码</el-dropdown-item
                  >
                  <el-dropdown-item divided @click.native="logOut"
                    >退出</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-header>
        <el-main>
          <!-- <el-scrollbar style="height: 100%; background-color: #fff">  @activePath="activePaths"-->
          <MentTag></MentTag>
          <keep-alive v-if="$route.meta.keepAlive">
            <router-view :key="$route.query.t"></router-view>
          </keep-alive>
          <router-view
            :key="$route.query.t"
            v-if="!$route.meta.keepAlive"
          ></router-view>

          <!-- <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view> -->
          <!-- </el-scrollbar> -->
        </el-main>
      </el-container>
    </el-container>
    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="isShowXGMM"
      @close="addClose"
      width="60%"
      v-el-drag-dialog
    >
      <el-form
        :model="query"
        :rules="formRules"
        ref="addRef"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="请输入新密码" prop="password">
          <el-input
            v-model="query.password"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="请再次输入新密码" prop="password1">
          <el-input
            v-model="query.password1"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowXGMM = false">取 消</el-button>
        <el-button type="primary" @click="addClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import { logout } from '../api/login.js'
import MenuTree from '../components/MentTree.vue'
import MentTag from '../components/MentTag.vue'
import { findMenuWithAuthority } from '../api/login.js'
import { getCurrentSysAdmin, changePassword } from '@/api/common.js'
export default {
  components: {
    MenuTree,
    MentTag,
  },
  data() {
    var validatePass1 = (rule, value, callback) => {
      let result
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        var express =
          /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
        result = express.test(value) // 满足条件时候 正则结果是true
        if (result) {
          callback()
        } else {
          callback(new Error('最少8个字符，需包含大、小写字母、特殊字符和数字'))
        }
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.query.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      // activePath: '',
      openeds: ['0'],
      Admin: undefined,
      togglecollapse: false,
      fullscreen: false,
      menuList: [],
      currentactivePathypt: 'Welcome',
      isShowXGMM: false,
      query: {
        password: '',
      },
      formRules: {
        // 添加表单的验证规则
        password: [
          {
            required: true,
            validator: validatePass1,
            trigger: 'blur',
          },
        ],
        password1: [
          {
            required: true,
            validator: validatePass2,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.findMenuWithAuthority()
    if (
      this.$cookies.get('activePathypt') &&
      this.$cookies.get('activePathypt') != 'Outerchain'
    ) {
      this.currentactivePathypt = this.$cookies.get('activePathypt')
      this.$router.push({ path: this.$cookies.get('activePathypt') })
    } else {
      this.currentactivePathypt = 'Welcome'
    }
  },
  watch: {
    '$store.state.activeTab'() {
      // 接收子组件的activePath值
      this.currentactivePathypt = this.$store.getters.getactivetabTitle
      // this.$cookies.set('activePath', this.$store.getters.getactivetabTitle, 0)
    },
  },
  computed: {
    // 获取那些需要 缓存的路由页面
    keepAlive() {
      return this.$store.getters.keepAlive
    },
  },
  methods: {
    addClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.addRef.resetFields()
    },
    addClick() {
      // 修改密码
      this.$refs.addRef.validate((valid) => {
        if (!valid) return
        if (this.query.password != this.query.password1) {
          return this.$message.warning('两次密码不一致，请重新输入')
        }
        changePassword(this.query).then((res) => {
          if (res.data.code === '200') {
            this.$message.success('密码修改成功')
            this.isShowXGMM = false
          }
        })
      })
    },
    getCurrentSysAdmin() {
      // 查询登入用户信息
      getCurrentSysAdmin().then((res) => {
        if (res.data.code === '200') {
          this.Admin = res.data.data.username
        }
      })
    },
    findMenuWithAuthority() {
      //获取用户菜单
      findMenuWithAuthority().then((res) => {
        if (res.data.code === '200') {
          this.getCurrentSysAdmin()
          this.menuList = res.data.data
        } else {
          return
        }
      })
    },
    // 设置活跃tag
    // activePaths(activePath) {
    //    接收子组件的activePath值
    //   this.currentactivePathypt = activePath
    //   this.$cookies.set('activePath', activePath, 0)
    // },
    togglebtn() {
      // 菜单的折叠于展开
      this.togglecollapse = !this.togglecollapse
    },
    handleFullScreen() {
      // 全屏事件
      let element = document.documentElement
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = !this.fullscreen
    },
    logOut() {
      logout().then(() => {
        // 退出登入
        this.$store.commit('setactivetagTitle', 'Welcome')
        this.$store.commit('deleteTab', 0)
        this.$cookies.remove('setifuserweakPassword')
        this.$cookies.remove('activePathypt')
        this.$cookies.remove('token')
        this.$cookies.remove('dadavid')
        window.sessionStorage.clear()
        window.localStorage.clear()
        this.$router.push('/Login')
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
}
::v-deep .el-header {
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    color: #fff;
    .togglebtn {
      font-size: 18px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .btn-rank {
      transform: rotate(45deg);
      margin: 0 10px;
      font-size: 24px;
      color: #fff;
    }
    .btnout {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        border-radius: 50%;
      }
    }
  }
}
.logo {
  color: #fff;
  height: 59px;
  padding-left: 70px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: url('../assets/pzlogo.png') left center no-repeat;
  background-size: 60px 40px;
  h4 {
    margin: 0;
    line-height: 59px;
  }
}
.user {
  font-size: 23px;
  color: #999;
}
::v-deep .el-dropdown {
  color: #fff;
}
::v-deep .el-container {
  height: 100%;
}
::v-deep .el-header,
.el-aside {
  background-color: rgb(51, 55, 65);
}
::v-deep .el-menu {
  overflow-x: hidden;
  overflow-y: hidden;
  border-right: none;
}
::v-deep .el-scrollbar {
  background-color: rgb(51, 55, 65);
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
::v-deep .el-main {
  padding: 10px;
  min-width: calc(100vw - 240px);
}
::v-deep .el-table thead {
  font-weight: normal;
  color: #333;
}
::v-deep .el-table {
  color: #333;
}
::v-deep .el-table th {
  font-size: 13px;
}
::v-deep .el-table tr {
  font-size: 13px;
}
::v-deep .el-card {
  min-height: 600px;
}
::v-deep .el-card__body {
  min-height: 600px;
  box-sizing: border-box;
}
</style>