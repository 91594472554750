<template>
  <div class="tags" @contextmenu.prevent="" ref="tags" v-if="tabList">
    <el-tag
      v-for="(item, index) in tabList"
      :key="index"
      :data-index="index"
      closable
      @close="removeTab(item, index)"
      @contextmenu.native.prevent="handleClickContextMenu($event)"
      @click="handleTagClick($event, item)"
      :type="item.path == activeTab ? 'danger' : ''"
    >
      {{ item.title }}
    </el-tag>

    <ul
      class="right-menu"
      :style="{ left: menuLeft, top: menuTop }"
      v-show="contextMenuVisible"
    >
      <li @click="refresh"><i class="el-icon-refresh-right"></i> 刷新页面</li>
      <li @click="currentremoveTab"><i class="el-icon-close"></i> 关闭当前</li>
      <li @click="handleTagClose">
        <i class="el-icon-circle-close"></i> 关闭其他
      </li>
      <li @click="removeallTab">
        <i class="el-icon-circle-close"></i> 全部关闭
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // 当前活跃的tabs
        activeTab: '',
        contextMenuVisible: false, // 是否显示菜单
        menuLeft: '', // 右键菜单距离浏览器左边的距离
        menuTop: '', // 右键菜单距离浏览器上边的距离
        tagIndex: 0, // 当前右键点击的tag的索引
        tag: {}, // 当前右键点击的tag对象
        tags: {}, // tab 列表
        currenttagIndex: 0, //当前点击的tag的索引
      };
    },
    components: {},
    computed: {
      tabList() {
        return this.$store.getters['getTabs'];
      },
    },
    watch: {
      $route: function() {
        this.setActiveTab();
        this.addTab();
      },
    },
    created() {
      window.addEventListener('click', (e) => {
        const target = e.target;
        if (target.nodeName !== 'SPAN') {
          // 只要点击的不是el-tag就可以关闭，因为el-tag是用span标签实现的
          this.contextMenuVisible = false;
        }
      });
    },
    mounted() {
      this.setActiveTab();
      this.addTab();
      window.addEventListener('click', (e) => {
        const target = e.target;
        if (target.nodeName !== 'SPAN') {
          // 只要点击的不是el-tag就可以关闭，因为el-tag是用span标签实现的
          this.contextMenuVisible = false;
        }
      });
    },
    methods: {
      // 配合左侧菜单联动
      setactivepath(path) {
        var items = path.split('/');
        var activepath = items.join('');
        this.$store.commit('setactivetagTitle', activepath);
      },
      // 设置活跃的tab
      setActiveTab() {
        this.active = [
          {
            path: this.$route.path,
            title: this.$route.meta.title,
          },
        ];
        this.$store.commit('setactiveTab', this.active);
        this.activeTab = this.$store.getters.getactiveTab[0].path;
      },
      // 刷新页面
      refresh() {
        this.$router.push({
          path: this.activeTab,
          query: {
            t: Date.now(),
          },
        });
      },
      // 添加tab
      addTab() {
        const { path, meta, query } = this.$route;
        const tab = {
          path,
          title: meta.title,
          query: query,
        };

        this.$store.commit('addTab', tab);
      },
      // 点击tab
      handleTagClick(event, tab) {
        this.$route.meta.keepAlive = true;
        const { path, query } = tab;
        this.activeTab = path;
        if (query) {
          this.$router.push({ path: path, query: query });
        } else {
          this.$router.push({ path: path });
        }
        this.setactivepath(path);
      },
      // 删除当前tab
      currentremoveTab() {
        // 当tag列表 为最后一个时
        if (this.tabList.length == 1) {
          let home = [];
          this.$store.commit('settabList', home);
          this.setactivepath('/helloWorld');
          this.$router.push({
            path: '/helloWorld',
            query: {
              t: Date.now(),
            },
          });
          return;
        } else {
          // 当前删除tag 和 活跃tag 相同时，且 为第一个tag
          if (
            this.tagIndex == 0 &&
            this.tabList[this.tagIndex].path ==
              this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: this.tagIndex,
              delCount: 1,
            };
            let path = this.tabList[this.tagIndex + 1].path;
            this.$store.commit('deleteTab', indexes);
            this.$router.push({ path: path });
            this.setactivepath(path);
            return;
          }
          // 当前删除tag 和 活跃tag 不相同时，且 为第一个tag
          if (
            this.tagIndex == 0 &&
            this.tabList[this.tagIndex].path !=
              this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: this.tagIndex,
              delCount: 1,
            };
            this.$store.commit('deleteTab', indexes);
            return;
          }

          //当前删除tag 和 活跃tag 相同时，且 为最后个tag
          if (
            this.tagIndex == this.tabList.length - 1 &&
            this.tabList[this.tagIndex].path ==
              this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: this.tagIndex,
              delCount: 1,
            };
            let path = this.tabList[this.tagIndex - 1].path;
            this.$store.commit('deleteTab', indexes);
            this.$router.push({ path: path });
            this.setactivepath(path);
            return;
          }

          //当前删除tag 和 活跃tag 不相同时，且 为最后个tag
          if (
            this.tagIndex == this.tabList.length - 1 &&
            this.tabList[this.tagIndex].path !=
              this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: this.tagIndex,
              delCount: 1,
            };
            this.$store.commit('deleteTab', indexes);
            return;
          }

          //当前删除tag 和 活跃tag 相同时  在中间列表
          if (
            this.tagIndex != 0 &&
            this.tagIndex != this.tabList.length - 1 &&
            this.tabList[this.tagIndex].path ==
              this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: this.tagIndex,
              delCount: 1,
            };
            let path = this.tabList[this.tagIndex - 1].path;
            this.$store.commit('deleteTab', indexes);
            this.$router.push({ path: path });
            this.setactivepath(path);
            return;
          }
          //当前删除tag 和 活跃tag 不相同时  在中间列表
          if (
            this.tagIndex != 0 &&
            this.tagIndex != this.tabList.length - 1 &&
            this.tabList[this.tagIndex].path !=
              this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: this.tagIndex,
              delCount: 1,
            };
            this.$store.commit('deleteTab', indexes);
            return;
          }
        }
      },
      // 删除tab
      removeTab(target, index) {
        // 当tag列表 为最后一个时
        if (this.tabList.length == 1) {
          let home = [];
          this.$store.commit('settabList', home);
          this.setactivepath('/helloWorld');
          this.$router.push({
            path: '/helloWorld',
            query: {
              t: Date.now(),
            },
          });
          return;
        } else {
          // 当前删除tag 和 活跃tag 相同时，且 为第一个tag
          if (
            index == 0 &&
            this.tabList[index].path ===
              this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: index,
              delCount: 1,
            };
            let path = this.tabList[index + 1].path;
            this.$store.commit('deleteTab', indexes);
            this.$router.push({ path: path });
            this.setactivepath(path);
            return;
          }
          // 当前删除tag 和 活跃tag 不相同时，且 为第一个tag
          if (
            index == 0 &&
            this.tabList[index].path != this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: index,
              delCount: 1,
            };
            this.$store.commit('deleteTab', indexes);
            return;
          }

          //当前删除tag 和 活跃tag 相同时，且 为最后个tag
          if (
            index == this.tabList.length - 1 &&
            this.tabList[index].path == this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: index,
              delCount: 1,
            };
            let path = this.tabList[index - 1].path;
            this.$store.commit('deleteTab', indexes);
            this.$router.push({ path: path });
            this.setactivepath(path);
            return;
          }

          //当前删除tag 和 活跃tag 不相同时，且 为最后个tag
          if (
            index == this.tabList.length - 1 &&
            this.tabList[index].path != this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: index,
              delCount: 1,
            };
            this.$store.commit('deleteTab', indexes);
            return;
          }
          //当前删除tag 和 活跃tag 相同时  在中间列表
          if (
            index != 0 &&
            index != this.tabList.length - 1 &&
            this.tabList[index].path == this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: index,
              delCount: 1,
            };
            let path = this.tabList[index - 1].path;
            this.$store.commit('deleteTab', indexes);
            this.$router.push({ path: path });
            this.setactivepath(path);
            return;
          }
          //当前删除tag 和 活跃tag 不相同时  在中间列表
          if (
            index != 0 &&
            index != this.tabList.length - 1 &&
            this.tabList[index].path != this.$store.getters.getactiveTab[0].path
          ) {
            let indexes = {
              index: index,
              delCount: 1,
            };
            this.$store.commit('deleteTab', indexes);
            return;
          }
        }
      },
      // 删除全部
      removeallTab() {
        let home = [];
        this.$store.commit('settabList', home);
        this.$router.push({
          path: '/helloWorld',
        });
        this.setactivepath('/helloWorld');
      },
      // 删除其他
      handleTagClose() {
        let tab = this.tabList[this.tagIndex];
        // 点击关闭其他，并且不是当前所在的页面
        if (tab.path == this.$store.getters.getactiveTab[0].path) {
          let indexes = {
            index: 0,
            delCount: this.tagIndex,
          };
          this.$store.commit('deleteTab', indexes);
          let indexe = {
            index: 1,
            delCount: this.tabList.length - 1,
          };
          this.$store.commit('deleteTab', indexe);
          return;
        } else {
          let indexes = {
            index: 0,
            delCount: this.tagIndex,
          };
          this.$store.commit('deleteTab', indexes);
          let indexe = {
            index: 1,
            delCount: this.tabList.length - 1,
          };
          this.$store.commit('deleteTab', indexe);
          this.$router.push({ path: tab.path });
          this.setactivepath(tab.path);
        }
      },
      // 右键显示菜单
      handleClickContextMenu(event) {
        const e = event || window.event;
        const target = e.target;
        this.menuLeft = e.layerX + 20 + 'px'; // 菜单出现的位置距离左侧的距离
        this.menuTop = e.layerY + 5 + 'px'; // 菜单出现的位置距离顶部的距离
        this.tagIndex = Number(target.getAttribute('data-index')); // 获取当前右击菜单的索引。从0开始
        this.contextMenuVisible = true; // 显示菜单
        this.tag = this.tabList[this.tagIndex]; // 当前右击的菜单信息
      },
    },
  };
</script>

<style lang="sass" scoped>
  .tags
    position: relative
    margin: 2px 0 10px 0
    .el-tag
      cursor: pointer
      margin-right: 5px
    .right-menu
      margin: 0
      background: #fff
      z-index: 3000
      position: absolute
      list-style-type: none
      padding: 5px 0
      border-radius: 4px
      font-size: 12px
      font-weight: 400
      color: #333
      -webkit-box-shadow: 2px 2px 3px 0 rgba(0,0,0,.3)
      box-shadow: 2px 2px 3px 0 rgba(0,0,0,.3)
      li
        margin: 0
        padding: 7px 16px
        cursor: pointer
</style>
